import moment from "moment";
import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import TimerComponent from "../../components/timerComponent";
import LoadingComponent from "../../components/loading-component";
import PaymentForm from "../../components/payment-forms/payment-form";
import languageService from "../../services/core/language-service";
import StringHelpers from "../../services/core/string-helpers";
import Microsite from "../../services/micro-site/microsite";
import micrositeService from "../../services/micro-site/microsite-service";
import ReservationBlock from "../../services/micro-site/reservationBlock";
import orderService from "../../services/order/order-service";
import { CardPaymentIntentResponse } from "../../services/payment/payment-intent-response";
import paymentService from "../../services/payment/payment-service";
import "./reserve-locker.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MinimalPrice, PricePerHour, PricePerMinute } from "../../assets/meta-table-keys";
import DateHelpers from "../../services/core/date-helpers";

interface IParamTypes {
  micrositeId: string;
  reservationBlockId: string;
  token: string;
}

export default function ReserveLockerPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const language: string = languageService.getLanguage();

  const { micrositeId, reservationBlockId, token } = useParams<IParamTypes>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reservationBlock, setReservationBlock] = useState<ReservationBlock | null>();
  const [timeUntilExpired, setTimeUntilExpired] = useState<number>();

  const [microsite, setMicrosite] = useState<Microsite | undefined>(undefined);
  const [paymentIntent, setPaymentIntent] = useState<CardPaymentIntentResponse>();

  useEffect(() => {
    //Gather the microsite and reservation block requested
    micrositeService.getMicrosite(micrositeId).then((microsite: Microsite | undefined) => {
      if (microsite) {
        setMicrosite(microsite);
        const reservationBlock = microsite.reservationBlocks.find((rb) => rb.id === reservationBlockId);
        setReservationBlock(reservationBlock);
        if (token) {
          orderService.getOrderById(microsite.organizationId, token, token).then((order) => {
            if (order) {
              if (order.payments && order.payments.length > 0) {
                history.push(`/my-reservations/${token}`);
              }

              paymentService.createPaymentIntent(order.id).then((paymentIntent: CardPaymentIntentResponse) => {
                setPaymentIntent(paymentIntent);
              });

              if (reservationBlock) {
                micrositeService
                  .getTimeUntilExpired(order.id)
                  .then((result) => {
                    setTimeUntilExpired(parseInt(StringHelpers.formatNumber(result, 0)));
                  })
                  .catch(() => {
                    setLoading(false);
                  })
                  .finally(() => setLoading(false));
              } else {
                setLoading(false);
              }
            } else {
              history.push(`/sites/reservation-expired/${micrositeId}`);
            }
          });
        }
      }
    });
  }, [micrositeId, reservationBlockId]);

  if (loading) {
    return <LoadingComponent loading={loading} />;
  }

  if (!reservationBlock) {
    return (
      <div className="page reserve-locker-page container ">
        <div className="col col-lg-6 offset-lg-3">
          <div className="card">{!reservationBlock && <div>{t("micro-site-reserve-locker.no-reservation-block")}</div>}</div>
        </div>
      </div>
    );
  }

  const until = reservationBlock ? moment(reservationBlock.startOn).add(reservationBlock.duration, "seconds").toDate() : new Date();
  const reservationBlockIsFree = reservationBlock.price === 0;
  const dateNow = new Date();
  const untilStart = (new Date(reservationBlock.startOn).getTime() - dateNow.getTime()) / 1000;

  const meta = reservationBlock?.meta;
  const pricePerHour = meta ? Number.parseFloat(meta[PricePerHour]) : null;
  const pricePerMinute = meta ? Number.parseFloat(meta[PricePerMinute]) : null;
  const minimalPrice = meta ? Number.parseFloat(meta[MinimalPrice]) : null;
  const paynow = pricePerHour || pricePerMinute ? false : true;

  return (
    <div className="page reserve-locker-page container">
      <div className="col col-sm-12 col-lg-8 offset-lg-2 page-background-boxing shadow">
        <div className="d-inline">
          <button className="btn fs-5 text-grey mt-2" onClick={() => history.push(`/sites/${micrositeId}`)}>
            <FontAwesomeIcon icon={["fas", "arrow-left"]} className="me-2" />
            {t("common.go-back")}
          </button>
        </div>
        <TimerComponent
          timeInSeconds={timeUntilExpired ?? 5 * 60}
          message="micro-site-page.msg-time-until-expired"
          onTimerEnds={() => {
            history.push(`/sites/reservation-expired/${micrositeId}`);
          }}
          className="mb-1 me-3 float-end text-grey timeLeft text-center d-inline"
        />

        <div className="card-body text-center text-grey mt-4">
          <div className="d-inline-block mt-0 pb-0 text-dark">
            {reservationBlock?.logoUrl && <img src={reservationBlock?.logoUrl} alt="" className="img-logo" />}
            <h2 className="mt-2 fs-3"> {reservationBlock?.name.get(language)}</h2>
          </div>
          <div className="mb-4 mt-3">
            <div className="mb-1">{t("micro-site-page.temp-locker-reserve")}</div>

            {reservationBlock.description.get(language) && <span className="mb-2">{reservationBlock.description.get(language)}</span>}

            {reservationBlock.startOn && (
              <>
                <TimerComponent
                  timeInSeconds={untilStart}
                  message="micro-site-page.locker-available-from"
                  onTimerEnds={() => null}
                  className="timeBeforeStart mb-1"
                />
                <Trans
                  i18nKey="micro-site-page.locker-available-to"
                  values={{
                    end: DateHelpers.formatDateTime(until, DateHelpers.UserTimeZone),
                  }}
                />
              </>
            )}
            {!reservationBlock.startOn && (
              <div className="durationAvailability">
                <Trans
                  i18nKey="micro-site-page.locker-available-now"
                  values={{ duration: DateHelpers.formatDuration(reservationBlock.duration) }}
                />
              </div>
            )}
          </div>

          {!!pricePerHour ||
            (!!pricePerMinute && (
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                  <div className="text-center">
                    <p>
                      <strong>{t("micro-site-page.cost-title")}</strong>
                      <br />
                      {!!pricePerHour &&
                        t("micro-site-page.cost-per-hour", {
                          cost: StringHelpers.formatPrice(pricePerHour + pricePerHour * reservationBlock.saleTaxRate),
                          currency: microsite?.currency,
                        })}
                      {!!pricePerMinute &&
                        t("micro-site-page.cost-per-minute", {
                          cost: StringHelpers.formatPrice(pricePerMinute + pricePerMinute * reservationBlock.saleTaxRate),
                          currency: microsite?.currency,
                        })}
                      <br />
                      {!!minimalPrice && (
                        <>
                          {t("micro-site-page.min-cost", {
                            cost: StringHelpers.formatPrice(minimalPrice + minimalPrice * reservationBlock.saleTaxRate),
                            currency: microsite?.currency,
                          })}
                          <br />
                        </>
                      )}
                      {t("micro-site-page.max-cost", {
                        cost: StringHelpers.formatPrice(reservationBlock.price + reservationBlock.price * reservationBlock.saleTaxRate),
                        currency: microsite?.currency,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            ))}

          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              {paymentIntent && token && microsite?.organizationId && (
                <>
                  <PaymentForm
                    paymentIntent={paymentIntent}
                    orderToken={token}
                    organizationId={microsite?.organizationId}
                    hasPayment={!reservationBlockIsFree}
                    cost={StringHelpers.formatPrice(reservationBlock.price + reservationBlock.price * reservationBlock.saleTaxRate)}
                    currency={microsite.currency}
                    paynow={paynow}
                    minPrice={
                      minimalPrice ? StringHelpers.formatPrice(minimalPrice + minimalPrice * reservationBlock.saleTaxRate) : undefined
                    }
                  />
                </>
              )}
            </div>
          </div>

          <div className="text-start row mt-4">
            <div className="col-5">
              <div className="text-start text-very-small">*{t("micro-site-page.charge-explenation-now")}</div>
              <div className="text-very-small">**{t("micro-site-page.privilage-explenation")}</div>
            </div>
            <div className="col-5 offset-2">
              <div className="text-danger text-very-small">{t("micro-site-page.causion-message")}</div>
            </div>
          </div>

          {!reservationBlockIsFree && paymentIntent && (
            <>
              <hr className="mt-4"></hr>
              <a
                href={
                  paymentIntent.providerType !== "Stripe"
                    ? language === "fr"
                      ? "https://squareup.com/ca/fr/security"
                      : "https://squareup.com/us/en/security"
                    : "https://stripe.com/docs/security"
                }
                target="_blank"
                rel="noreferrer"
              >{`${t("micro-site-page.provider-security-message")} ${paymentIntent.providerType}`}</a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
