import "./organizations-page.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Member from "../../services/member/member";
import MemberRoleOrganization from "../../services/member/member-role-organization";

type Props = {
  member: Member;
  activeOrganization: MemberRoleOrganization | null;
  changeActiveOrganization: any;
};

export default function OrganizationsPage(props: Props) {
  const { member, activeOrganization, changeActiveOrganization } = props;

  const history = useHistory();

  const [associatedOrganizations] = useState<MemberRoleOrganization[] | undefined>(member.associatedOrganizations);

  const { t } = useTranslation();

  return (
    <div className="container pt-5 page organizations-page">
      <div className="row">
        {/* Organizations */}
        <div className="col-12 offset-lg-2 col-lg-8">
          <div className="card">
            <div className="card-header align-items-center">
              <h2>{t("organizations-page.title")}</h2>
            </div>

            <div className="card-body">
              <div className="col-12">
                {/*
                <Link className="btn btn-primary mb-4" to="/create-organization">
                  {t("organizations-page.create-organization")}
                </Link>
                */}

                <table className="table table-hover row-clikable">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t("organizations-page.organization")}</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {member &&
                      associatedOrganizations &&
                      associatedOrganizations.map((associatedOrganization) => {
                        const current: boolean =
                          activeOrganization &&
                          activeOrganization.entity &&
                          associatedOrganization.entity &&
                          associatedOrganization.entity.id === activeOrganization.entity.id
                            ? true
                            : false;

                        return (
                          <tr
                            key={associatedOrganization.entity?.id}
                            onClick={() => history.push(`organizations/${associatedOrganization.entity?.id}`)}
                          >
                            <td>{current && <FontAwesomeIcon className="text-success" icon={["fas", "check-circle"]} />}</td>
                            <td>{associatedOrganization.entity?.name}</td>
                            <td>
                              {!current && (
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={(ev) => {
                                    ev.stopPropagation();
                                    changeActiveOrganization(associatedOrganization);
                                  }}
                                >
                                  {t("organizations-page.select-organization")}
                                </button>
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-primary"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  history.push(`organizations/${associatedOrganization.entity?.id}`);
                                }}
                              >
                                <FontAwesomeIcon icon={["fas", "pen"]} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    {(!member || !associatedOrganizations) && (
                      <tr>
                        <td colSpan={2}>{t("organizations-page.no-organization-associated")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
