import moment from "moment";
import StringHelpers from "../../services/core/string-helpers";
import { MinimalPrice, PricePerHour, PricePerMinute, ResetTime } from "../../assets/meta-table-keys";
import { Trans, useTranslation } from "react-i18next";
import ReservationBlock from "../../services/micro-site/reservationBlock";
import Microsite from "../../services/micro-site/microsite";
import DateHelpers from "../../services/core/date-helpers";

type EventCardProps = {
  reservationBlock: ReservationBlock;
  microsite: Microsite;
  language: string;
  reserve: (reservationBlock: ReservationBlock) => void;
  canShowCreateReservation: (reservationBlock: ReservationBlock) => boolean;
};

export default function EventCard(props: EventCardProps) {
  const { reservationBlock, microsite, language, reserve, canShowCreateReservation } = props;
  const { t } = useTranslation();

  const until = moment(reservationBlock.startOn).add(reservationBlock.duration, "seconds").toDate();
  const priceFormatted = StringHelpers.formatPrice(reservationBlock.price + reservationBlock.price * reservationBlock.saleTaxRate);

  const showCreateReservation = canShowCreateReservation(reservationBlock);
  const pricePerHour = Number.parseFloat(reservationBlock.meta[PricePerHour]);
  const pricePerMinute = Number.parseFloat(reservationBlock.meta[PricePerMinute]);

  if (!pricePerHour && !pricePerMinute)
    return (
      <div className="card" key={reservationBlock.id}>
        <div className="card-header">
          {reservationBlock?.logoUrl && <img src={reservationBlock?.logoUrl} alt="" className="img-logo" />}
          <h2 className="mt-4"> {reservationBlock.name.get(language)}</h2>
        </div>

        <div className="card-body">
          {microsite.logoUrl && <img src={microsite.logoUrl} alt="" className="img-logo float-end" />}
          <p>
            {reservationBlock.description && reservationBlock.description.length > 0 && (
              <>
                {reservationBlock.description.get(language)}
                <br />
              </>
            )}
            {reservationBlock.startOn ? (
              <Trans
                i18nKey="micro-site-page.locker-available"
                values={{
                  start: DateHelpers.formatDateTime(new Date(reservationBlock.startOn), DateHelpers.UserTimeZone),
                  end: DateHelpers.formatDateTime(until, DateHelpers.UserTimeZone),
                }}
              />
            ) : (
              <Trans
                i18nKey="micro-site-page.locker-available-now"
                values={{ duration: DateHelpers.formatDuration(reservationBlock.duration) }}
              />
            )}
          </p>

          <p>{t("micro-site-page.cost", { cost: priceFormatted, currency: microsite.currency })}</p>

          {showCreateReservation ? (
            <div className="mt-4">
              <button className="btn btn-lg btn-primary" onClick={() => reserve(reservationBlock)}>
                {t("micro-site-page.create-reservation")}
              </button>
            </div>
          ) : (
            <p>
              {t("micro-site-page.reservation-not-available", {
                date: DateHelpers.formatDateTime(new Date(reservationBlock.availableStart.toString()), "America/Toronto"),
              })}
            </p>
          )}
        </div>
      </div>
    );

  const minimalPrice = Number.parseFloat(reservationBlock.meta[MinimalPrice]);
  const minimalPriceFormatted = StringHelpers.formatPrice(minimalPrice + minimalPrice * reservationBlock.saleTaxRate);
  const resetTime = reservationBlock.meta[ResetTime];
  const resetTimeDate = DateHelpers.getNextDayWithTime(resetTime, DateHelpers.UserTimeZone);
  let cost = "";
  if (pricePerHour) cost = StringHelpers.formatPrice(pricePerHour + pricePerHour * reservationBlock.saleTaxRate);
  else if (pricePerMinute) cost = StringHelpers.formatPrice(pricePerMinute + pricePerMinute * reservationBlock.saleTaxRate);

  return (
    <div className="card" key={reservationBlock.id}>
      <div className="card-header">
        {reservationBlock?.logoUrl && <img src={reservationBlock?.logoUrl} alt="" className="img-logo" />}
        <h2 className="mt-4"> {reservationBlock.name.get(language)}</h2>
      </div>

      <div className="card-body">
        {microsite.logoUrl && <img src={microsite.logoUrl} alt="" className="img-logo float-end" />}
        <p>
          {reservationBlock.description && reservationBlock.description.length > 0 && (
            <>
              {reservationBlock.description.get(language)}
              <br />
            </>
          )}
          <Trans
            i18nKey="micro-site-page.reservation-valid-until"
            values={{
              month: DateHelpers.getMonth(resetTimeDate),
              day: DateHelpers.getDay(resetTimeDate),
              time: DateHelpers.formatTime(resetTime, DateHelpers.UserTimeZone),
            }}
          />
        </p>

        <p>
          <strong>{t("micro-site-page.cost-title")}</strong>
          <br />

          {!!pricePerHour && t("micro-site-page.cost-per-hour", { cost: cost, currency: microsite.currency })}
          {!!pricePerMinute && t("micro-site-page.cost-per-minute", { cost: cost, currency: microsite.currency })}
          {minimalPrice > 0 && (
            <>
              <br />
              {t("micro-site-page.min-cost", { cost: minimalPriceFormatted, currency: microsite.currency })}
            </>
          )}
          <br />
          {t("micro-site-page.max-cost", { cost: priceFormatted, currency: microsite.currency })}
        </p>

        {showCreateReservation ? (
          <div className="mt-4">
            <button className="btn btn-lg btn-primary" onClick={() => reserve(reservationBlock)}>
              {t("micro-site-page.create-reservation")}
            </button>
          </div>
        ) : (
          <p>
            {t("micro-site-page.reservation-not-available", {
              date: DateHelpers.formatDateTime(new Date(reservationBlock.availableStart.toString()), "America/Toronto"),
            })}
          </p>
        )}
      </div>
    </div>
  );
}
