import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../customers/customers-page.scss";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import Organization from "../../services/organization/organization";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../../components/loading-component";
import PagingComponent from "../../components/paging-component";
import { Customer } from "../../services/order/customer";
import { AddButtonComponent, EditButtonComponent, SyncButtonComponent } from "../../components/button-component";
import languageService from "../../services/core/language-service";
import { GlobalFilter } from "../../components/globalfilter-component";
import memberService from "../../services/member/member-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  organizationId: String | undefined;
  setEditMember: Function;
  setMemberEdited: Function;
  setIsNewUser: Function;
}

export default function MemberRoleTable(props: IProps) {
  const { organizationId, setEditMember, setMemberEdited, setIsNewUser } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [members, setMembers] = useState<Array<Member>>([]);
  const [filteredMembers, setFilteredMembers] = useState<Array<Member>>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (organizationId) {
      setPageSize(10);
      refresh();
    }
  }, []);

  //Table configuration
  const columnsTemp = [
    {
      Header: t("customers-page-table-header.FirstName"),
      accessor: "firstname",
    },
    {
      Header: t("customers-page-table-header.LastName"),
      accessor: "lastname",
    },
    {
      Header: t("customers-page-table-header.Email"),
      accessor: "email",
    },
    {
      Header: "Role over Organization",
      accessor: "role",
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line
      columns: useMemo(() => columnsTemp, [languageService.language]),
      // eslint-disable-next-line
      data: useMemo(() => updateTableCustomersData(filteredMembers, organizationId), [filteredMembers]),
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  const filterMembers = (list: Array<Member>) => {
    if (!members) {
      setMembers([]);
      return;
    }
    setFilteredMembers(list);
  };

  useEffect(() => {
    setGlobalFilter(state.globalFilter);
  }, [filteredMembers]);

  return (
    <div className="container page customers-page">
      <h1>Members</h1>

      <div className="toolbar">
        <AddButtonComponent title={"Create Member"} onClick={() => editMember(new Member(), true)}></AddButtonComponent>

        <SyncButtonComponent className="ms-2" onClick={() => refresh()}></SyncButtonComponent>

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div>
        <div className="row mt-3">
          <div className="col">
            <div className="customer card">
              <div className="card-body">
                <table {...getTableProps()} className="table  table-sm table-hover row-clikable">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          return <th {...column.getHeaderProps()}>{column.render("Header")}</th>;
                        })}

                        {/* Empty column header for actions */}
                        <td></td>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {loading && (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <LoadingComponent loading={loading} />
                        </td>
                      </tr>
                    )}

                    {!loading &&
                      page.map((row, i) => {
                        prepareRow(row);

                        let customer = row.original as any;

                        return (
                          <tr {...row.getRowProps()} onClick={() => editMember(customer, false)}>
                            <td {...row.cells[0].getCellProps()}>{customer.firstname}</td>
                            <td {...row.cells[1].getCellProps()}>{customer.lastname}</td>
                            <td {...row.cells[2].getCellProps()}>{customer.email}</td>
                            <td {...row.cells[3].getCellProps()}>{customer.role}</td>
                            <td>
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  editMember(customer, false);
                                }}
                              >
                                <FontAwesomeIcon icon={["fas", "pen"]} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <PagingComponent
          countTextResource={"customers-page.item-count"}
          count={rows.length}
          pageLength={pageOptions.length}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </div>
    </div>
  );

  function editMember(member: Member, isNewUser: boolean) {
    setMemberEdited(member);
    setIsNewUser(isNewUser);
    setEditMember(true);
  }

  function refresh() {
    const orgId = organizationId;
    if (orgId) {
      setLoading(true);

      memberService
        .getMembers(orgId)
        .then((data) => {
          setMembers(data);
          filterMembers(data);
        })
        .catch(() => {
          ToastHelpers.error(t("orders-page.error-refresh"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
}

function updateTableCustomersData(members: Member[], organizationId: String | undefined): Array<any> {
  const tableData: Array<any> = [];
  if (members) {
    members.forEach((member: Member) => {
      var unique: Array<String> = [];

      member.associatedOrganizations.forEach((org) => {
        if (org) {
          if (org.entity) {
            if (!unique.includes(org.entity.id) && org.entity.id == organizationId) {
              tableData.push({
                firstname: member.firstname?.toString() ?? "",
                lastname: member.lastname?.toString() ?? "",
                email: member.email.toString() ?? "",
                entity: "Organization",
                role: org.roles.toString() ?? "",
                associatedHubs: member.associatedHubs,
                associatedOrganizations: member.associatedOrganizations,
              });
              unique.push(org.entity.id);
            }
          }
        }
      });
    });
  }
  return tableData;
}

// if (entityType == "Hub") {
//   requestMember.associatedHubs?.forEach((org) => {
//     if (org.entity?.id == chosenOrgId) {
//       var arr: Array<string> = org.roles ?? [];

//       (permission + ",").split(",").forEach((role: String) => {
//         arr.push(role?.toString());
//       });

//       org.roles = arr;
//     }
//   });
// }
