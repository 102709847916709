import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import authService from "../services/auth/auth-service";
import Member from "../services/member/member";
import MemberRoleOrganization from "../services/member/member-role-organization";
import memberService from "../services/member/member-service";
import organizationService from "../services/member/organization-service";
import GetLocketgoLogoSource, { AssetColors } from "../assets/assets-colors";

interface IHeaderNavBarProps {
  member: Member;
  activeOrganization: MemberRoleOrganization | null;
  changeLanguage: any;
  changeMember: any;
}

function HeaderNavbar(props: IHeaderNavBarProps) {
  const { member, activeOrganization, changeLanguage, changeMember } = props;
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const [micrositesLength, setMicrositesLength] = useState<number>(0);

  const { t } = useTranslation();
  const orgContributor = memberService.isOrganizationContributor(member, activeOrganization?.entity?.id);

  const collapseClasses = `${isNavCollapsed ? "collapse" : ""} navbar-collapse`;

  const onItemClick = () => {
    setIsNavCollapsed(true);
  };

  async function login(): Promise<void> {
    const loginResponse = await authService.login();
    changeMember(loginResponse.member);
  }

  let accountDisplay = "<unkown>";
  if (member) {
    accountDisplay = member.firstname;
    if (!accountDisplay && member.email) {
      accountDisplay = member.email;
    }
  }

  useEffect(
    () => {
      if (member) {
        let organizationId = activeOrganization?.entity?.id;
        if (organizationId) {
          let organization = member.associatedOrganizations.find((organization) => organization.entity?.id == organizationId);
          if (member && organization && memberService.isOrganizationContributor(member, organizationId)) {
            organizationService.getAllMicrositesOfOrganization(organizationId).then((microsites) => {
              setMicrositesLength(microsites.length);
            });
          }
        }
      }
    },
    // eslint-disable-next-line
    [activeOrganization]
  );

  return (
    <nav className="navbar navbar-expand-lg fixed-top shadow">
      <div className="container">
        <HeaderIcon logo={AssetColors.White} />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
          onClick={() => setIsNavCollapsed(!isNavCollapsed)}
        >
          <span>
            <FontAwesomeIcon color="white" icon={["fas", "bars"]} />
          </span>
        </button>

        <div className={collapseClasses} id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {member && (
              <>
                {orgContributor && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/orders" onClick={onItemClick}>
                        {t("common.locker-orders")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/customers" onClick={onItemClick}>
                        {t("common.customers")}
                      </Link>
                    </li>

                    {memberService.isLocketgoAdmin(member) && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/database-admin" onClick={onItemClick}>
                          {t("common.adminPage")}
                        </Link>
                      </li>
                    )}

                    {micrositesLength > 0 && (
                      <li className="nav-item">
                        <Link className="nav-link" to={`/organizations/${activeOrganization?.entity?.id}/microsites`} onClick={onItemClick}>
                          {t("common.microsites")}
                        </Link>
                      </li>
                    )}
                  </>
                )}

                {member.associatedHubs?.length > 0 && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/hubs" onClick={onItemClick}>
                      {t("common.hubs")}
                    </Link>
                  </li>
                )}
              </>
            )}
          </ul>

          <ul className="navbar-nav mr-auto align-items-center">
            {member && member.associatedOrganizations?.length > 0 && (
              <>
                <li className="nav-item nav-item-space-right">
                  <Link className="nav-link" to="/organizations" onClick={onItemClick}>
                    {activeOrganization?.entity?.name.toUpperCase()}
                  </Link>
                </li>
              </>
            )}

            {member && (
              <>
                <li className="nav-item nav-item-space-right nav-item-user">
                  <Link className="nav-link" to="/member-profile" onClick={onItemClick}>
                    <FontAwesomeIcon icon={["fas", "user"]} />
                    <span>{accountDisplay.toUpperCase()}</span>
                  </Link>
                </li>
              </>
            )}
            {!member && (
              <li className="nav-item nav-item-space-right">
                <button
                  className="nav-link"
                  onClick={async () => {
                    onItemClick();
                    await login();
                  }}
                >
                  {t("account.login")}
                </button>
              </li>
            )}
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => {
                  onItemClick();
                  changeLanguage();
                }}
              >
                {t("language.display")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

interface IHeaderIconProps {
  logo: AssetColors;
}

function HeaderIcon(props: IHeaderIconProps) {
  let imgSource = GetLocketgoLogoSource(props.logo);
  return (
    <Link className="navbar-brand" to="/">
      <img src={imgSource} alt="logo" className="app-logo" />
    </Link>
  );
}

export { HeaderNavbar, HeaderIcon };
