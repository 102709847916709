import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { ActionButtonComponent } from "../../components/button-component";
import { InputComponent } from "../../components/input";
import WaitingComponent from "../../components/waiting-component";
import StringHelpers from "../../services/core/string-helpers";
import ToastHelpers from "../../services/core/toast-helpers";
import Member from "../../services/member/member";
import MemberRoleOrganization from "../../services/member/member-role-organization";
import memberService from "../../services/member/member-service";
import organizationService from "../../services/member/organization-service";

type Props = {
  member: Member;
};

interface IApprobationProps {
  state: string;
}

interface IParamTypes {
  organizationId: string;
}

function ApprobationComponent(props: IApprobationProps) {
  const { state } = props;
  const { t } = useTranslation();

  return <>{t(`hub-association-state.${state}`)}</>;
}

export default function OrganizationPage(props: Props) {
  const { member } = props;
  const { organizationId } = useParams<IParamTypes>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  const [activeOrganization, setActiveOrganization] = useState<MemberRoleOrganization | undefined>(undefined);
  const [organizationName, setOrganizationName] = useState<string | undefined>(undefined);
  const [streetNumber, setStreetNumber] = useState<string | undefined>(undefined);
  const [streetNumberError, setStreetNumberError] = useState<string | undefined>(undefined);
  const [street, setStreet] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [postalCode, setPostalCode] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const isAdmin = memberService.isOrganizationAdmin(member, organizationId);

  const [micrositesLength, setMicrositesLength] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    memberService
      .getMemberMe()
      .then((me) => {
        let organization = me?.associatedOrganizations.find((organization) => organization.entity?.id == organizationId);
        if (organization) {
          setActiveOrganization(organization);
          setOrganizationName(organization.entity?.name);
          setCity(organization.entity?.city);
          setPostalCode(organization.entity?.postalCode);

          setStreet(organization.entity?.street);
          setStreetNumber(organization.entity?.streetNumber);

          setCountry(organization.entity?.country ? organization.entity?.country : "Canada");
          setState(organization.entity?.state ? organization.entity?.state : "QC");
          organizationService
            .getAllMicrositesOfOrganization(organizationId)
            .then((microsites) => {
              setMicrositesLength(microsites.length);
              setLoading(false);
            })
            .catch(() => {
              ToastHelpers.error(t("microsites-page.microsites-error"));
            });
        }
      })
      .catch(() => {
        ToastHelpers.error(t("common.error-generic"));
      });
  }, []);

  return (
    <div className="container pt-3 page organizations-page">
      {loading && <WaitingComponent />}
      {!loading && (
        <>
          <div className="col-12 offset-lg-2 col-lg-8">
            <div className="page-breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link className="microsites" to="/organizations">
                    {t("common.organizations")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">{organizationName}</li>
              </ul>
              <h1>{`${organizationName}`}</h1>
            </div>

            {/* Microsites button */}
            {micrositesLength !== 0 && member && memberService.isOrganizationContributor(member, organizationId) && (
              <div>
                <Link className=" microsites btn btn-primary" to={`/organizations/${organizationId}/microsites`}>
                  {t("organization-page.microsites-page")}
                </Link>
              </div>
            )}
          </div>

          {/* Edit organization */}
          <div className="col-12 offset-lg-2 col-lg-8">
            <div className="card">
              <div className="card-header align-items-center">
                <button className="btn-close float-end" onClick={() => history.push(`/organizations`)}></button>
                <h2>{t("organization-page.address")}</h2>
              </div>
              <div className="card-body">
                <form className="row g-3" onSubmit={(ev) => ev.preventDefault()}>
                  <div className="col-md-3">
                    <InputComponent
                      type="number"
                      regExp={/^[0-9]*$/}
                      labelText={t("member-profile.streetNumber") + "*"}
                      setValue={(value: string) => {
                        setStreetNumber(value);
                        StringHelpers.isStringOnlyNumbers(value)
                          ? setStreetNumberError(undefined)
                          : setStreetNumberError(t("member-profile.street-number-error"));
                      }}
                      value={streetNumber ? streetNumber : ""}
                      disabled={!isAdmin}
                      msgError={streetNumberError}
                    />
                  </div>
                  <div className="col-md-9">
                    <InputComponent
                      labelText={t("member-profile.street") + "*"}
                      setValue={(value: string) => setStreet(value)}
                      value={street ? street : ""}
                      disabled={!isAdmin}
                    />
                  </div>
                  <div className="col-md-8">
                    <InputComponent
                      labelText={t("member-profile.city") + "*"}
                      setValue={(value: string) => setCity(value)}
                      value={city ? city : ""}
                      disabled={!isAdmin}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputComponent
                      labelText={t("member-profile.postalCode") + "*"}
                      setValue={(value: string) => setPostalCode(value)}
                      value={postalCode ? postalCode : ""}
                      disabled={!isAdmin}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputComponent labelText={t("member-profile.state") + "*"} setValue={(value: string) => {}} value={state} disabled />
                  </div>
                  <div className="col-md-6">
                    <InputComponent
                      labelText={t("member-profile.country") + "*"}
                      setValue={(value: string) => {}}
                      value={country}
                      disabled
                    />
                  </div>

                  {isAdmin && (
                    <div className="col-md-6">
                      <ActionButtonComponent
                        type="submit"
                        title={t("organization-page.save-address")}
                        onClick={() => saveAddress()}
                        className="btn btn-primary"
                        disabled={saveDisabled()}
                      />
                      <button className="btn btn-md btn-primary-outline" type="submit" onClick={() => history.push(`/organizations`)}>
                        {t("common.cancel")}
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>

          {/* My roles */}
          <div className="col-12 offset-lg-2 col-lg-8">
            <div className="card">
              <div className="card-header align-items-center">
                <h2>{t("organization-page.organization-roles")}</h2>
              </div>

              <div className="card-body">
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("organization-page.organization-roles-name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeOrganization?.roles?.map((role) => {
                        return (
                          <tr key={role}>
                            <td className="text-start">{role}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Associated Hubs */}
          <div className="col-12 offset-lg-2 col-lg-8">
            <div className="card">
              <div className="card-header align-items-center">
                <h2>{t("organization-page.associated-hubs")}</h2>
              </div>

              <div className="card-body">
                <div className="col-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">{t("organization-page.location")}</th>
                        <th>{t("organization-page.approbation")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeOrganization?.entity?.associatedHubs.map((associatedHub) => {
                        return (
                          <tr key={associatedHub.hubId}>
                            <td>{associatedHub.hubId}</td>
                            <td>
                              <ApprobationComponent state={associatedHub.state} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
  function saveDisabled(): boolean {
    return !(street && streetNumber && city && postalCode) || streetNumberError !== undefined;
  }

  function saveAddress() {
    if (street && streetNumber && city && postalCode && activeOrganization) {
      setLoading(true);
      organizationService
        .updateOrganization(organizationId, streetNumber, street, city, postalCode, state, country)
        .catch((error: any) => {
          ToastHelpers.error(t("common.error-refresh"));
        })
        .finally(() => setLoading(false));
    }
  }
}
